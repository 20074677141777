var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-filter box1" }, [
    _c("div", { staticClass: "form mb-0", attrs: { action: "#" } }, [
      _c("h4", [_vm._v("Filter By")]),
      _c("div", { staticClass: "filter-options price-filter" }, [
        _c("h6", [_vm._v("Price")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.price,
              expression: "price"
            }
          ],
          staticClass: "form-control-range mb-3",
          attrs: {
            type: "range",
            min: _vm.minPrice,
            max: _vm.maxPrice,
            "data-toggle": "tooltip",
            "data-placement": "top",
            title: _vm.price
          },
          domProps: { value: _vm.price },
          on: {
            change: _vm.priceChanged,
            __r: function($event) {
              _vm.price = $event.target.value
            }
          }
        }),
        _c("ul", { staticClass: "list" }, [
          _c("li", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.minPrice,
                  expression: "minPrice"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.minPrice },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.minPrice = $event.target.value
                }
              }
            })
          ]),
          _vm._m(0),
          _c("li", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.price,
                  expression: "price"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.price },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.price = $event.target.value
                }
              }
            })
          ])
        ])
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#noise-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "noise-filter"
            }
          },
          [_vm._v(" Noise level ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "noise-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.noiseLevels,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select noise levels",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.noiseChanged },
              model: {
                value: _vm.noise,
                callback: function($$v) {
                  _vm.noise = $$v
                },
                expression: "noise"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#speed-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "speed-filter"
            }
          },
          [_vm._v(" Maximum spin speed (rpm) - NEW (2010/30/EC) ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "speed-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.maximumSpeeds,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select maximum speeds",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.maximumSpeedChanged },
              model: {
                value: _vm.maximumSpeed,
                callback: function($$v) {
                  _vm.maximumSpeed = $$v
                },
                expression: "maximumSpeed"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#feature-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "feature-filter"
            }
          },
          [_vm._v(" Key feature ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "feature-filter" }
          },
          [
            _c("ul", { staticClass: "list" }, [
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.automaticStain,
                        expression: "automaticStain"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-0" },
                    domProps: {
                      checked: Array.isArray(_vm.automaticStain)
                        ? _vm._i(_vm.automaticStain, null) > -1
                        : _vm.automaticStain
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.automaticStain,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.automaticStain = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.automaticStain = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.automaticStain = $$c
                          }
                        },
                        _vm.automaticStainChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-0" } }, [
                    _vm._v("Automatic stain removal system")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ecarf,
                        expression: "ecarf"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-1" },
                    domProps: {
                      checked: Array.isArray(_vm.ecarf)
                        ? _vm._i(_vm.ecarf, null) > -1
                        : _vm.ecarf
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.ecarf,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.ecarf = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.ecarf = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.ecarf = $$c
                          }
                        },
                        _vm.ecarfChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-1" } }, [
                    _vm._v("ECARF")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.efficientSilentDrive,
                        expression: "efficientSilentDrive"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-2" },
                    domProps: {
                      checked: Array.isArray(_vm.efficientSilentDrive)
                        ? _vm._i(_vm.efficientSilentDrive, null) > -1
                        : _vm.efficientSilentDrive
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.efficientSilentDrive,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.efficientSilentDrive = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.efficientSilentDrive = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.efficientSilentDrive = $$c
                          }
                        },
                        _vm.efficientSilentDriveChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-2" } }, [
                    _vm._v("Efficient SilentDrive")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.fillingDisplay,
                        expression: "fillingDisplay"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-3" },
                    domProps: {
                      checked: Array.isArray(_vm.fillingDisplay)
                        ? _vm._i(_vm.fillingDisplay, null) > -1
                        : _vm.fillingDisplay
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.fillingDisplay,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.fillingDisplay = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.fillingDisplay = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.fillingDisplay = $$c
                          }
                        },
                        _vm.fillingDisplayChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-3" } }, [
                    _vm._v("Load display with dosage recommendation")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.autoDose,
                        expression: "autoDose"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-5" },
                    domProps: {
                      checked: Array.isArray(_vm.autoDose)
                        ? _vm._i(_vm.autoDose, null) > -1
                        : _vm.autoDose
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.autoDose,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.autoDose = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.autoDose = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.autoDose = $$c
                          }
                        },
                        _vm.autoDoseChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-5" } }, [
                    _vm._v("i-Dos")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.reloadFunction,
                        expression: "reloadFunction"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-6" },
                    domProps: {
                      checked: Array.isArray(_vm.reloadFunction)
                        ? _vm._i(_vm.reloadFunction, null) > -1
                        : _vm.reloadFunction
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.reloadFunction,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.reloadFunction = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.reloadFunction = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.reloadFunction = $$c
                          }
                        },
                        _vm.reloadFunctionChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-6" } }, [
                    _vm._v("Reload function")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.aquaStop,
                        expression: "aquaStop"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-7" },
                    domProps: {
                      checked: Array.isArray(_vm.aquaStop)
                        ? _vm._i(_vm.aquaStop, null) > -1
                        : _vm.aquaStop
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.aquaStop,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.aquaStop = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.aquaStop = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.aquaStop = $$c
                          }
                        },
                        _vm.aquaStopChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-7" } }, [
                    _vm._v("AquaStop")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.display,
                        expression: "display"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-8" },
                    domProps: {
                      checked: Array.isArray(_vm.display)
                        ? _vm._i(_vm.display, null) > -1
                        : _vm.display
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.display,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.display = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.display = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.display = $$c
                          }
                        },
                        _vm.displayChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-8" } }, [
                    _vm._v("Display")
                  ])
                ])
              ])
            ])
          ]
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#capacity-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "capacity-filter"
            }
          },
          [_vm._v(" Capacity (kg) ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "capacity-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.capacities,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select capacities",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.capacityChanged },
              model: {
                value: _vm.capacity,
                callback: function($$v) {
                  _vm.capacity = $$v
                },
                expression: "capacity"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#installation-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "installation-filter"
            }
          },
          [_vm._v(" Installation type ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "installation-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.instTypes,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select installation types",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.installationChanged },
              model: {
                value: _vm.installation,
                callback: function($$v) {
                  _vm.installation = $$v
                },
                expression: "installation"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#height-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "height-filter"
            }
          },
          [_vm._v(" Height ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "height-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.heights,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select heights",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.heightChanged },
              model: {
                value: _vm.height,
                callback: function($$v) {
                  _vm.height = $$v
                },
                expression: "height"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#width-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "width-filter"
            }
          },
          [_vm._v(" Width ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "width-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.widths,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select widths",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.widthChanged },
              model: {
                value: _vm.width,
                callback: function($$v) {
                  _vm.width = $$v
                },
                expression: "width"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#depth-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "depth-filter"
            }
          },
          [_vm._v(" Depth ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "depth-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.depths,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select depths",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.depthChanged },
              model: {
                value: _vm.depth,
                callback: function($$v) {
                  _vm.depth = $$v
                },
                expression: "depth"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#color-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "color-filter"
            }
          },
          [_vm._v(" Colour ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "color-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.colors,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select colours",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.colorChanged },
              model: {
                value: _vm.color,
                callback: function($$v) {
                  _vm.color = $$v
                },
                expression: "color"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#feet-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "feet-filter"
            }
          },
          [_vm._v(" Adjustable feet ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "feet-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.adjustableFeets,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select adjustable feets",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.adjustableFeetChanged },
              model: {
                value: _vm.adjustableFeet,
                callback: function($$v) {
                  _vm.adjustableFeet = $$v
                },
                expression: "adjustableFeet"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#program-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "program-filter"
            }
          },
          [_vm._v(" List of programs ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "program-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.programs,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select programs",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.programChanged },
              model: {
                value: _vm.program,
                callback: function($$v) {
                  _vm.program = $$v
                },
                expression: "program"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#connectivity-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "connectivity-filter"
            }
          },
          [_vm._v(" Connectivity type ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "connectivity-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.connectivities,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select connectivity types",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.connectivityChanged },
              model: {
                value: _vm.connectivity,
                callback: function($$v) {
                  _vm.connectivity = $$v
                },
                expression: "connectivity"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#loading-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "loading-filter"
            }
          },
          [_vm._v(" Loading type ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "loading-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.loadingTypes,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select loading types",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.loadingTypeChanged },
              model: {
                value: _vm.loadingType,
                callback: function($$v) {
                  _vm.loadingType = $$v
                },
                expression: "loadingType"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#optimization-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "optimization-filter"
            }
          },
          [_vm._v(" Wash optimization ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "optimization-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.optimizations,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select wash optimizations",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.optimizationChanged },
              model: {
                value: _vm.optimization,
                callback: function($$v) {
                  _vm.optimization = $$v
                },
                expression: "optimization"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#appliance-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "appliance-filter"
            }
          },
          [_vm._v(" Smart Appliance ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "appliance-filter" }
          },
          [
            _c("ul", { staticClass: "list" }, [
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.remoteDiagnostics,
                        expression: "remoteDiagnostics"
                      }
                    ],
                    attrs: { type: "checkbox", id: "appliance-1" },
                    domProps: {
                      checked: Array.isArray(_vm.remoteDiagnostics)
                        ? _vm._i(_vm.remoteDiagnostics, null) > -1
                        : _vm.remoteDiagnostics
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.remoteDiagnostics,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.remoteDiagnostics = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.remoteDiagnostics = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.remoteDiagnostics = $$c
                          }
                        },
                        _vm.remoteDiagnosticsChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "appliance-1" } }, [
                    _vm._v("Remote Diagnostics")
                  ])
                ])
              ])
            ])
          ]
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#energy-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "energy-filter"
            }
          },
          [_vm._v(" Energy efficiency class ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "energy-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.energyClasses,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select energy classes",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.energyChanged },
              model: {
                value: _vm.energy,
                callback: function($$v) {
                  _vm.energy = $$v
                },
                expression: "energy"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#hinge-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "hinge-filter"
            }
          },
          [_vm._v(" Door hinge ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "hinge-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.doorHinges,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select door hinges",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.doorHingeChanged },
              model: {
                value: _vm.doorHinge,
                callback: function($$v) {
                  _vm.doorHinge = $$v
                },
                expression: "doorHinge"
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("span", [_vm._v("to")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }