<template>
  <div class="product-filter box1">
    <div action="#" class="form mb-0">
      <h4>Filter By</h4>
      <div class="filter-options price-filter">
        <h6>Price</h6>
        <input type="range" class="form-control-range mb-3" v-model="price" :min="minPrice" :max="maxPrice"
          @change="priceChanged" data-toggle="tooltip" data-placement="top" :title="price" />
        <ul class="list">
          <li><input type="text" class="form-control" v-model="minPrice" readonly></li>
          <li><span>to</span></li>
          <li><input type="text" class="form-control" v-model="price" readonly></li>
        </ul>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#noise-filter" role="button" aria-expanded="true"
          aria-controls="noise-filter">
          Noise level
        </h6>
        <div class="wrapper collapse show" id="noise-filter">
          <VueMultiselect v-model="noise" :options="noiseLevels" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select noise levels" :searchable="false" selectLabel=""
            deselectLabel="" @input="noiseChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#speed-filter" role="button" aria-expanded="true"
          aria-controls="speed-filter">
          Maximum spin speed (rpm) - NEW (2010/30/EC)
        </h6>
        <div class="wrapper collapse show" id="speed-filter">
          <VueMultiselect v-model="maximumSpeed" :options="maximumSpeeds" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select maximum speeds" :searchable="false" selectLabel=""
            deselectLabel="" @input="maximumSpeedChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#feature-filter" role="button" aria-expanded="true"
          aria-controls="feature-filter">
          Key feature
        </h6>
        <div class="wrapper collapse show" id="feature-filter">
          <ul class="list">
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="automaticStain" id="feature-0" @change="automaticStainChanged" />
                <label for="feature-0">Automatic stain removal system</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="ecarf" id="feature-1" @change="ecarfChanged" />
                <label for="feature-1">ECARF</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="efficientSilentDrive" id="feature-2"
                  @change="efficientSilentDriveChanged" />
                <label for="feature-2">Efficient SilentDrive</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="fillingDisplay" id="feature-3" @change="fillingDisplayChanged" />
                <label for="feature-3">Load display with dosage recommendation</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="autoDose" id="feature-5" @change="autoDoseChanged" />
                <label for="feature-5">i-Dos</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="reloadFunction" id="feature-6" @change="reloadFunctionChanged" />
                <label for="feature-6">Reload function</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="aquaStop" id="feature-7" @change="aquaStopChanged" />
                <label for="feature-7">AquaStop</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="display" id="feature-8" @change="displayChanged" />
                <label for="feature-8">Display</label>
              </div>
            </li>
          </ul>
        </div>
      </div>


      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#capacity-filter" role="button" aria-expanded="true"
          aria-controls="capacity-filter">
          Capacity (kg)
        </h6>
        <div class="wrapper collapse show" id="capacity-filter">
          <VueMultiselect v-model="capacity" :options="capacities" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select capacities" :searchable="false" selectLabel="" deselectLabel=""
            @input="capacityChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#installation-filter" role="button" aria-expanded="true"
          aria-controls="installation-filter">
          Installation type
        </h6>
        <div class="wrapper collapse show" id="installation-filter">
          <VueMultiselect v-model="installation" :options="instTypes" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select installation types" :searchable="false" selectLabel=""
            deselectLabel="" @input="installationChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#height-filter" role="button" aria-expanded="true"
          aria-controls="height-filter">
          Height
        </h6>
        <div class="wrapper collapse show" id="height-filter">
          <VueMultiselect v-model="height" :options="heights" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select heights" :searchable="false" selectLabel="" deselectLabel=""
            @input="heightChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#width-filter" role="button" aria-expanded="true"
          aria-controls="width-filter">
          Width
        </h6>
        <div class="wrapper collapse show" id="width-filter">
          <VueMultiselect v-model="width" :options="widths" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select widths" :searchable="false" selectLabel="" deselectLabel=""
            @input="widthChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#depth-filter" role="button" aria-expanded="true"
          aria-controls="depth-filter">
          Depth
        </h6>
        <div class="wrapper collapse show" id="depth-filter">
          <VueMultiselect v-model="depth" :options="depths" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select depths" :searchable="false" selectLabel="" deselectLabel=""
            @input="depthChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#color-filter" role="button" aria-expanded="true"
          aria-controls="color-filter">
          Colour
        </h6>
        <div class="wrapper collapse show" id="color-filter">
          <VueMultiselect v-model="color" :options="colors" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select colours" :searchable="false" selectLabel="" deselectLabel=""
            @input="colorChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#feet-filter" role="button" aria-expanded="true"
          aria-controls="feet-filter">
          Adjustable feet
        </h6>
        <div class="wrapper collapse show" id="feet-filter">
          <VueMultiselect v-model="adjustableFeet" :options="adjustableFeets" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select adjustable feets" :searchable="false" selectLabel=""
            deselectLabel="" @input="adjustableFeetChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#program-filter" role="button" aria-expanded="true"
          aria-controls="program-filter">
          List of programs
        </h6>
        <div class="wrapper collapse show" id="program-filter">
          <VueMultiselect v-model="program" :options="programs" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select programs" :searchable="false" selectLabel="" deselectLabel=""
            @input="programChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#connectivity-filter" role="button" aria-expanded="true"
          aria-controls="connectivity-filter">
          Connectivity type
        </h6>
        <div class="wrapper collapse show" id="connectivity-filter">
          <VueMultiselect v-model="connectivity" :options="connectivities" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select connectivity types" :searchable="false" selectLabel=""
            deselectLabel="" @input="connectivityChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#loading-filter" role="button" aria-expanded="true"
          aria-controls="loading-filter">
          Loading type
        </h6>
        <div class="wrapper collapse show" id="loading-filter">
          <VueMultiselect v-model="loadingType" :options="loadingTypes" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select loading types" :searchable="false" selectLabel=""
            deselectLabel="" @input="loadingTypeChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#optimization-filter" role="button" aria-expanded="true"
          aria-controls="optimization-filter">
          Wash optimization
        </h6>
        <div class="wrapper collapse show" id="optimization-filter">
          <VueMultiselect v-model="optimization" :options="optimizations" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select wash optimizations" :searchable="false" selectLabel=""
            deselectLabel="" @input="optimizationChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#appliance-filter" role="button" aria-expanded="true"
          aria-controls="appliance-filter">
          Smart Appliance
        </h6>
        <div class="wrapper collapse show" id="appliance-filter">
          <ul class="list">
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="remoteDiagnostics" id="appliance-1"
                  @change="remoteDiagnosticsChanged" />
                <label for="appliance-1">Remote Diagnostics</label>
              </div>
            </li>

          </ul>
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#energy-filter" role="button" aria-expanded="true"
          aria-controls="energy-filter">
          Energy efficiency class
        </h6>
        <div class="wrapper collapse show" id="energy-filter">
          <VueMultiselect v-model="energy" :options="energyClasses" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select energy classes" :searchable="false" selectLabel=""
            deselectLabel="" @input="energyChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#hinge-filter" role="button" aria-expanded="true"
          aria-controls="hinge-filter">
          Door hinge
        </h6>
        <div class="wrapper collapse show" id="hinge-filter">
          <VueMultiselect v-model="doorHinge" :options="doorHinges" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select door hinges" :searchable="false" selectLabel="" deselectLabel=""
            @input="doorHingeChanged" />
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import _ from "lodash";
import ProductService from "@/services/product.service";
import VueMultiselect from "vue-multiselect";

export default {
  name: "WashingMachineFilters",
  data: function () {
    return {
      noiseLevels: [],
      energyClasses: [],
      instTypes: [],
      maximumSpeeds: [],
      serieses: [],
      capacities: [],
      heights: [],
      colors: [],
      widths: [],
      depths: [],
      adjustableFeets: [],
      programs: [],
      connectivities: [],
      loadingTypes: [],
      optimizations: [],
      doorHinges: [],
      minPrice: 0,
      maxPrice: 0,

      price: 0,
      noise: [],
      energy: [],
      installation: [],
      maximumSpeed: [],
      series: [],
      capacity: [],
      height: [],
      color: [],
      width: [],
      depth: [],
      adjustableFeet: [],
      program: [],
      connectivity: [],
      loadingType: [],
      optimization: [],
      doorHinge: [],
      automaticStain: false,
      ecarf: false,
      efficientSilentDrive: false,
      fillingDisplay: false,
      autoDose: false,
      reloadFunction: false,
      aquaStop: false,
      remoteDiagnostics: false,
      display: false,
    };
  },
  components: {
    VueMultiselect,
  },
  watch: {
    // call again the method if the route changes
    selectedBrands: function () {
      this.bindValues();
    },
  },
  mounted() {
    this.bindValues();
    if (this.selectedFilters.category == this.selectedSubCategory.id) {
      this.existingFilters();
    }
  },
  computed: {
    selectedSubCategory() {
      return this.$store.state.subCategory.selectedSubCategory;
    },
    selectedBrands() {
      return this.$store.state.brand.selectedBrands;
    },
    selectedFilters() {
      return this.$store.state.filters.selectedFilters;
    }
  },
  methods: {
    bindValues() {
      ProductService.minMaxPriceFor(
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.minPrice = response.price__min;
        this.maxPrice = response.price__max;
        this.price = response.price__max;
        if (this.selectedFilters.price__lte) {
          this.price = Number(this.selectedFilters.price__lte);
        }
      });
      ProductService.valuesFor(
        "attr_energy_class_2017",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.energyClasses = response;
      });
      ProductService.valuesFor(
        "attr_noise_marketing",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.noiseLevels = response;
      });
      ProductService.valuesFor(
        "attr_inst_type",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.instTypes = response;
      });
      ProductService.valuesFor(
        "attr_spin_max_2010",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.maximumSpeeds = response;
      });
      ProductService.valuesFor(
        "attr_cap_cotton_2010",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.capacities = response;
      });
      ProductService.valuesFor(
        "series",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.serieses = response;
      });
      ProductService.valuesFor(
        "attr_height",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.heights = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_width",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.widths = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_depth",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.depths = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_col_main",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.colors = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_feet_adj",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.adjustableFeets = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_list_prgr",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.programs = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_homeconnect_connectivity",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.connectivities = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_loading",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.loadingTypes = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_door_hinge",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.doorHinges = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_wash_optimize",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.optimizations = response.filter(
          (item) => !["", null].includes(item)
        );
      });
    },


    priceChanged() {
      let filters = { price__lte: this.price };
      this.$emit("changeFilters", filters);
    },

    energyChanged() {
      let filters = {
        attr_energy_class_2017__in: _.map(this.energy, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    noiseChanged() {
      let filters = {
        attr_noise_marketing__in: _.map(this.noise, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    seriesChanged() {
      let filters = {
        series__in: _.map(this.series, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    installationChanged() {
      let filters = {
        attr_inst_type__in: _.map(this.installation, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    maximumSpeedChanged() {
      let filters = {
        attr_spin_max_2010__in: _.map(this.maximumSpeed, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    capacityChanged() {
      let filters = {
        attr_cap_cotton_2010__in: _.map(this.capacity, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    heightChanged() {
      let filters = {
        attr_height__in: _.map(this.height, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    widthChanged() {
      let filters = {
        attr_width__in: _.map(this.width, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    depthChanged() {
      let filters = {
        attr_depth__in: _.map(this.depth, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    colorChanged() {
      let filters = {
        attr_col_main__in: _.map(this.color, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    adjustableFeetChanged() {
      let filters = {
        attr_feet_adj__in: _.map(this.adjustableFeet, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    programChanged() {
      let filters = {
        attr_list_prgr__in: _.map(this.program, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    specialFunctionChanged() {
      let filters = {
        attr_sonderfunktionen__in: _.map(this.specialFunction, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    connectivityChanged() {
      let filters = {
        attr_homeconnect_connectivity__in: _.map(this.connectivity, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    loadingTypeChanged() {
      let filters = {
        attr_loading__in: _.map(this.loadingType, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    doorHingeChanged() {
      let filters = {
        attr_door_hinge__in: _.map(this.doorHinge, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    optimizationChanged() {
      let filters = {
        attr_wash_optimize__in: _.map(this.optimization, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    automaticStainChanged() {
      let filters = {
        attr_autostainremovalsystem: this.automaticStain ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    ecarfChanged() {
      let filters = {
        attr_ecarf: this.ecarf ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    efficientSilentDriveChanged() {
      let filters = {
        attr_ecosilencedrive: this.efficientSilentDrive ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    fillingDisplayChanged() {
      let filters = {
        attr_fillingdisplay: this.fillingDisplay ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    autoDoseChanged() {
      let filters = {
        attr_automaticdosagesystem: this.autoDose ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    reloadFunctionChanged() {
      let filters = {
        attr_reloadfunction: this.reloadFunction ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    aquaStopChanged() {
      let filters = {
        attr_aquastop: this.aquaStop ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    remoteDiagnosticsChanged() {
      let filters = {
        attr_remote_diagnostics: this.remoteDiagnostics ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    displayChanged() {
      let filters = {
        attr_indicator_count_down: this.display ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    existingFilters() {
      this.remoteDiagnostics = this.selectedFilters.attr_remote_diagnostics;
      this.reloadFunction = this.selectedFilters.attr_reloadfunction;
      this.automaticStain = this.selectedFilters.attr_autostainremovalsystem;
      this.ecarf = this.selectedFilters.attr_ecarf;
      this.efficientSilentDrive = this.selectedFilters.attr_ecosilencedrive;
      this.fillingDisplay = this.selectedFilters.attr_fillingdisplay;
      this.autoDose = this.selectedFilters.attr_automaticdosagesystem;
      this.aquaStop = this.selectedFilters.attr_aquastop;
      this.display = this.selectedFilters.attr_indicator_count_down;


      if (this.selectedFilters.attr_noise_marketing__in) {
        let sortedNoises = [];
        let unsortedNoises = this.selectedFilters.attr_noise_marketing__in.split('","');
        unsortedNoises.map((noise) => {
          noise = noise.replaceAll('"', '')
          sortedNoises.push(noise)
        });
        this.noise = sortedNoises;
      }

      if (this.selectedFilters.attr_energy_class_2017__in) {
        let sortedEnergies = [];
        let unsortedEnergies = this.selectedFilters.attr_energy_class_2017__in.split('","');
        unsortedEnergies.map((energy) => {
          energy = energy.replaceAll('"', '')
          sortedEnergies.push(energy)
        });
        this.energy = sortedEnergies;
      }

      if (this.selectedFilters.attr_cap_cotton_2010__in) {
        let sortedCapacities = [];
        let unsortedCapacities = this.selectedFilters.attr_cap_cotton_2010__in.split('","');
        unsortedCapacities.map((capacity) => {
          capacity = capacity.replaceAll('"', '')
          sortedCapacities.push(capacity)
        });
        this.capacity = sortedCapacities;
      }

      if (this.selectedFilters.series__in) {
        let sortedSeries = [];
        let unsortedSeries = this.selectedFilters.series__in.split('","');
        unsortedSeries.map((series) => {
          series = series.replaceAll('"', '')
          sortedSeries.push(series)
        });
        this.series = sortedSeries;
      }

      if (this.selectedFilters.attr_inst_type__in) {
        let sortedInstallations = [];
        let unsortedInstallations = this.selectedFilters.attr_inst_type__in.split('","');
        unsortedInstallations.map((installation) => {
          installation = installation.replaceAll('"', '')
          sortedInstallations.push(installation)
        });
        this.installation = sortedInstallations;
      }

      if (this.selectedFilters.attr_spin_max_2010__in) {
        let sortedSpeeds = [];
        let unsortedSpeeds = this.selectedFilters.attr_spin_max_2010__in.split('","');
        unsortedSpeeds.map((speed) => {
          speed = speed.replaceAll('"', '')
          sortedSpeeds.push(speed)
        });
        this.maximumSpeed = sortedSpeeds;
      }
      if (this.selectedFilters.attr_height__in) {
        let sortedHeights = [];
        let unsortedHeights = this.selectedFilters.attr_height__in.split('","');
        unsortedHeights.map((height) => {
          height = height.replaceAll('"', "");
          sortedHeights.push(height);
        });
        this.height = sortedHeights;
      }
      if (this.selectedFilters.attr_width__in) {
        let sortedWidths = [];
        let unsortedWidths = this.selectedFilters.attr_width__in.split('","');
        unsortedWidths.map((width) => {
          width = width.replaceAll('"', "");
          sortedWidths.push(width);
        });
        this.width = sortedWidths;
      }
      if (this.selectedFilters.attr_depth__in) {
        let sortedDepths = [];
        let unsortedDepths = this.selectedFilters.attr_depth__in.split('","');
        unsortedDepths.map((depth) => {
          depth = depth.replaceAll('"', "");
          sortedDepths.push(depth);
        });
        this.depth = sortedDepths;
      }
      if (this.selectedFilters.attr_col_main__in) {
        let sortedColors = [];
        let unsortedColors = this.selectedFilters.attr_col_main__in.split('","');
        unsortedColors.map((color) => {
          color = color.replaceAll('"', "");
          sortedColors.push(color);
        });
        this.color = sortedColors;
      }
      if (this.selectedFilters.attr_feet_adj__in) {
        let sortedFeets = [];
        let unsortedFeets = this.selectedFilters.attr_feet_adj__in.split('","');
        unsortedFeets.map((feet) => {
          feet = feet.replaceAll('"', "");
          sortedFeets.push(feet);
        });
        this.adjustableFeet = sortedFeets;
      }
      if (this.selectedFilters.attr_list_prgr__in) {
        let sortedPrograms = [];
        let unsortedPrograms = this.selectedFilters.attr_list_prgr__in.split(
          '","'
        );
        unsortedPrograms.map((program) => {
          program = program.replaceAll('"', "");
          sortedPrograms.push(program);
        });
        this.program = sortedPrograms;
      }
      if (this.selectedFilters.attr_sonderfunktionen__in) {
        let sortedFunctions = [];
        let unsortedFunctions = this.selectedFilters.attr_sonderfunktionen__in.split(
          '","'
        );
        unsortedFunctions.map((func) => {
          func = func.replaceAll('"', "");
          sortedFunctions.push(func);
        });
        this.specialFunction = sortedFunctions;
      }
      if (this.selectedFilters.attr_homeconnect_connectivity__in) {
        let sortedConnectivities = [];
        let unsortedConnectivities = this.selectedFilters.attr_homeconnect_connectivity__in.split(
          '","'
        );
        unsortedConnectivities.map((connectivity) => {
          connectivity = connectivity.replaceAll('"', "");
          sortedConnectivities.push(connectivity);
        });
        this.connectivity = sortedConnectivities;
      }
      if (this.selectedFilters.attr_loading__in) {
        let sortedLoadings = [];
        let unsortedLoadings = this.selectedFilters.attr_loading__in.split('","');
        unsortedLoadings.map((loading) => {
          loading = loading.replaceAll('"', "");
          sortedLoadings.push(loading);
        });
        this.loadingType = sortedLoadings;
      }
      if (this.selectedFilters.attr_door_hinge__in) {
        let sortedHinges = [];
        let unsortedHinges = this.selectedFilters.attr_door_hinge__in.split('","');
        unsortedHinges.map((hinge) => {
          hinge = hinge.replaceAll('"', "");
          sortedHinges.push(hinge);
        });
        this.doorHinge = sortedHinges;
      }
      if (this.selectedFilters.attr_wash_optimize__in) {
        let sortedOptimizations = [];
        let unsortedOptimizations = this.selectedFilters.attr_wash_optimize__in.split('","');
        unsortedOptimizations.map((optimization) => {
          optimization = optimization.replaceAll('"', "");
          sortedOptimizations.push(optimization);
        });
        this.optimization = sortedOptimizations;
      }
    }
  },
};
</script>

<style scoped></style>